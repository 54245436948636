body {
  font-family: 'Poppins', sans-serif;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 700;
}

html {
  z-index: 0;
  font-family: 'Poppins', sans-serif;
}

/* img {
  clip-path: circle(73.2% at 51% 23%);
  background-color: #2563EB;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
 width: 100%;
} */

* {
  scroll-behavior: smooth;
}

div p {
  margin: 0;
}
.loader-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0b0b0b;
  display: grid;
  place-items: center;
}

.loader {
  border: 16px solid #e3e3e3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.fade-out-section{
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section {


  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {

  opacity: 1;
  transform: none;
  visibility: visible;
}
.nav-link-transition {
  text-decoration: none;
}

.nav-link-transition::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #2563eb;
  transition: width .3s;
}

.nav-link-transition:hover::after {
  width: 100%;
  transition: width .3s;
}
.full-w-btn{
  width: 16%;
}
